.closed {
  display: flex;
  padding: 15px 21px;


  .closed-time__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 5px;
    width: 100%;
    color: #020617;
  
  
    .closed-time__range {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      height: 90%;
      width: 45%;

      span {
        margin: 0;
        font-size: 16px;
      }

      p {
        margin: 3px 0;
        font-size: 12px;
        opacity: 0.5;
      }
    }

    .closed-time__switch {
      h2 {
        font-size: 14px;
      }
    }
  }
  .closed-button {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-top: 5px;
    
    img {
      max-width: 40px;
      cursor: pointer;
    }
  }
}