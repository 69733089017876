.documentation-wrapper {
  padding: 0 30px;

  .close-btn {
    display: flex;
    padding: 0;
    height: 35px;
    align-items: center;
    margin-right: 25px;
    margin-left: 5px;


    svg {
      fill: #02061780;
    }

    &:hover {
      svg {
        fill: #020617;
      }
    }
  }

  .documentation-table-cell {
    min-width: min-content;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    gap: 10px;

    p {
      margin: 0;
    }
  }


  .loader-cell-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
  }

  .table-cell-btn-wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .selected-all-hidden {

    #select-all {
      display: none;
    }
  }

  .rt-thead {
    text-transform: capitalize;
  }
}