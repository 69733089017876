.limit-size-header {
  align-items: center;
  padding: 0 55px !important;
  gap: 15px;

  h5 {
    flex: 1 1 100%;
  }
}

.modal-body {
  .message {
    color: #020617;
    font-size: 18px;
    padding: 0 41px;
  }
}

.modal-footer {
  font-size: 18px;
}