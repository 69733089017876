.dashboard-info {
    width: 100%;
    height: 100%;
    background-size: contain;
    position: absolute;
    bottom: 0;

    &-container {
        float: right;

        button {
            margin: 12px 5px 0 0;
            padding-left: 21px;
            padding-right: 21px;
            color: #020617;
            font-size: 16px;
            font-weight: 500;
            line-height: 30px;
            letter-spacing: 0.27px;
            background-color: transparent;
            border: 1px solid var(--primary-color);
            border-radius: 48px;
            cursor: pointer;

            &:hover {
                color: #020617;
                background-color: var(--primary-color);
            }
        }
    }
}