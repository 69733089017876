.documentation-wrapper {
  .upload-modal-header {
    align-items: center;
    padding: 0 40px !important;
    margin-bottom: 30px !important;

    .close-btn {
      margin-right: 0;
    }
  }

  .upload-modal-body {
    padding: 0 !important;
    min-height: 120px;

    .files-list {
      display: flex;
      flex-direction: column;
      width: 100%;
      color: #020617;
      gap: 5px;
      padding: 0px 40px 0 40px;

      .file-size {
        font-weight: 400;
      }

      li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        word-break: break-all;
        padding: 10px;
        padding-right: 21px;
        border-radius: 2px;
        border: 2px solid #0206171a;

        .close-btn {
          margin-right: 0px;
          width: 11px;
        }

        button {
          background-color: transparent !important;
        }
      }
    }

    .ant-upload {
      border: none !important;
    }

    .ant-upload-drag-container {
      padding: 0 40px;
      display: flex;
      min-height: 120px;
      align-items: center;
      justify-content: space-between;

      .drop-file-text {
        align-items: center;
        color: #020617;
        opacity: 0.5;
        font-size: 12px;
        margin-top: 5px;

        &.transparency {
          opacity: 0;
        }
      }

      .exportBtn {
        min-width: 150px;
      }

      .chooseFile-btn {
        background-color: var(--primary-color) !important;
      }
    }


  }

  .modal-content .modal-header .modal-title {
    margin-left: 0 !important;
  }
}